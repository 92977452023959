import React from 'react';

const notFoundPage = () => (
  <>
    <h1>404</h1>
    <h3>Page Not Found!</h3>
  </>
)

export default notFoundPage
